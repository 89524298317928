@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
/* @import url('https://video-react.github.io/assets/video-react.css'); */
@import '~video-react/dist/video-react.css';

@layer base {
  html {
    font-family: 'Open Sans', sans-serif;
    background: #f5f5f5;
  }
}

:root {
  color-scheme: only light;
}
